import { create } from 'zustand'



// Define a type for the store's state
type CategoryImageMap = Map<string, string>; // Mapping slug to imageURL

type CategoryImageStore = {
  images: CategoryImageMap;
  // eslint-disable-next-line no-unused-vars
  fetchImages: (locale: string) => Promise<void>;
  // eslint-disable-next-line no-unused-vars
  getImageUrlBySlug: (slug: string) => string | undefined;
};

// Create the store
const menuImageStore: any = create<CategoryImageStore>((set) => ({
  images: new Map(),
  fetchImages: async (locale: string) => {
    try {
      const response = await fetch(`/api/menuimage?locale=${locale}`);
      const data = await response.json();

      // Create a new map for the images
      const newImages = new Map<string, string>();

      // Check if data is an array and has items
      if (Array.isArray(data)) {
        data.forEach((item: { slug: string; featuredImage: string }) => {
          if (item.slug && item.featuredImage) {
            newImages.set(item.slug.toLowerCase(), item.featuredImage);
            console.log(`Set image for ${item.slug}:`, item.featuredImage);
          }
        });
      }
      set({ images: newImages });
    } catch (error) {
      console.error('Error fetching menu images:', error);
      set({ images: new Map() });
    }
  },
  getImageUrlBySlug: (slug: string) => {
    const state = menuImageStore.getState();
    const imageUrl = state.images.get(slug.toLowerCase());
    console.log(`Getting image for slug ${slug}:`, imageUrl);
    return imageUrl;
  },
}));

export default menuImageStore;
